const Destinations = [
    {
        title: 'Puerto Princesa',
        code: 'PPS',
        url: '/destination/puerto-princesa',
        content: `<p>Nestled within the pristine island of Palawan lies Puerto Princesa, a city of natural wonders and enchanting beauty. Often referred to as the "City in a Forest", it boasts a harmonious blend of urban advancement and untouched nature. From the mesmerizing subterranean river in the Puerto Princesa Subterranean River National Park to its vibrant marine life in Honda Bay, the city provides a delightful mix of adventures waiting to be explored.</p>
        <br/>
        <p>Stepping into Puerto Princesa, you're greeted by stretches of powdery-white beaches, crystal-clear waters, and a horizon painted with lush mountains and age-old limestone formations. It's no wonder that this city has become a popular gateway to the wider archipelagic wonders of Palawan. Whether you're looking for serene beaches, thriving underwater ecosystems, or a touch of local culture, Puerto Princesa stands as a testament to the Philippines' claim as a tropical paradise.
        </p>
        <br/>
        <p>Beyond the natural attractions, Puerto Princesa exudes a rich history and culture. The hospitable locals, delectable seafood dishes, and handcrafted souvenirs encapsulate the spirit of Filipino warmth and creativity. As a destination that seamlessly combines nature, adventure, and cultural immersion, Puerto Princesa truly offers a taste of the best of Palawan.</p>`
    },
    {
        title: 'Port Barton',
        code: 'PBT',
        url: '/destination/port-barton',
        content: `<p>Nestled within the pristine island of Palawan lies Puerto Princesa, a city of natural wonders and enchanting beauty. Often referred to as the "City in a Forest", it boasts a harmonious blend of urban advancement and untouched nature. From the mesmerizing subterranean river in the Puerto Princesa Subterranean River National Park to its vibrant marine life in Honda Bay, the city provides a delightful mix of adventures waiting to be explored.</p>
        <br/>
        <p>Stepping into Puerto Princesa, you're greeted by stretches of powdery-white beaches, crystal-clear waters, and a horizon painted with lush mountains and age-old limestone formations. It's no wonder that this city has become a popular gateway to the wider archipelagic wonders of Palawan. Whether you're looking for serene beaches, thriving underwater ecosystems, or a touch of local culture, Puerto Princesa stands as a testament to the Philippines' claim as a tropical paradise.
        </p>
        <br/>
        <p>Beyond the natural attractions, Puerto Princesa exudes a rich history and culture. The hospitable locals, delectable seafood dishes, and handcrafted souvenirs encapsulate the spirit of Filipino warmth and creativity. As a destination that seamlessly combines nature, adventure, and cultural immersion, Puerto Princesa truly offers a taste of the best of Palawan.</p>`
    },
    {
        title: 'El Nido',
        code: 'ELN',
        url: '/destination/el-nido',
        content: `<p>Nestled within the pristine island of Palawan lies Puerto Princesa, a city of natural wonders and enchanting beauty. Often referred to as the "City in a Forest", it boasts a harmonious blend of urban advancement and untouched nature. From the mesmerizing subterranean river in the Puerto Princesa Subterranean River National Park to its vibrant marine life in Honda Bay, the city provides a delightful mix of adventures waiting to be explored.</p>
        <br/>
        <p>Stepping into Puerto Princesa, you're greeted by stretches of powdery-white beaches, crystal-clear waters, and a horizon painted with lush mountains and age-old limestone formations. It's no wonder that this city has become a popular gateway to the wider archipelagic wonders of Palawan. Whether you're looking for serene beaches, thriving underwater ecosystems, or a touch of local culture, Puerto Princesa stands as a testament to the Philippines' claim as a tropical paradise.
        </p>
        <br/>
        <p>Beyond the natural attractions, Puerto Princesa exudes a rich history and culture. The hospitable locals, delectable seafood dishes, and handcrafted souvenirs encapsulate the spirit of Filipino warmth and creativity. As a destination that seamlessly combines nature, adventure, and cultural immersion, Puerto Princesa truly offers a taste of the best of Palawan.</p>`
    },
    {
        title: 'San Vicente',
        code: 'SVC',
        url: '/destination/san-vicente',
        content: `<p>Nestled within the pristine island of Palawan lies Puerto Princesa, a city of natural wonders and enchanting beauty. Often referred to as the "City in a Forest", it boasts a harmonious blend of urban advancement and untouched nature. From the mesmerizing subterranean river in the Puerto Princesa Subterranean River National Park to its vibrant marine life in Honda Bay, the city provides a delightful mix of adventures waiting to be explored.</p>
        <br/>
        <p>Stepping into Puerto Princesa, you're greeted by stretches of powdery-white beaches, crystal-clear waters, and a horizon painted with lush mountains and age-old limestone formations. It's no wonder that this city has become a popular gateway to the wider archipelagic wonders of Palawan. Whether you're looking for serene beaches, thriving underwater ecosystems, or a touch of local culture, Puerto Princesa stands as a testament to the Philippines' claim as a tropical paradise.
        </p>
        <br/>
        <p>Beyond the natural attractions, Puerto Princesa exudes a rich history and culture. The hospitable locals, delectable seafood dishes, and handcrafted souvenirs encapsulate the spirit of Filipino warmth and creativity. As a destination that seamlessly combines nature, adventure, and cultural immersion, Puerto Princesa truly offers a taste of the best of Palawan.</p>`
    },
    {
        title: 'Balabac',
        code: 'BLB',
        url: '/destination/balabac',
        content: `<p>Nestled within the pristine island of Palawan lies Puerto Princesa, a city of natural wonders and enchanting beauty. Often referred to as the "City in a Forest", it boasts a harmonious blend of urban advancement and untouched nature. From the mesmerizing subterranean river in the Puerto Princesa Subterranean River National Park to its vibrant marine life in Honda Bay, the city provides a delightful mix of adventures waiting to be explored.</p>
        <br/>
        <p>Stepping into Puerto Princesa, you're greeted by stretches of powdery-white beaches, crystal-clear waters, and a horizon painted with lush mountains and age-old limestone formations. It's no wonder that this city has become a popular gateway to the wider archipelagic wonders of Palawan. Whether you're looking for serene beaches, thriving underwater ecosystems, or a touch of local culture, Puerto Princesa stands as a testament to the Philippines' claim as a tropical paradise.
        </p>
        <br/>
        <p>Beyond the natural attractions, Puerto Princesa exudes a rich history and culture. The hospitable locals, delectable seafood dishes, and handcrafted souvenirs encapsulate the spirit of Filipino warmth and creativity. As a destination that seamlessly combines nature, adventure, and cultural immersion, Puerto Princesa truly offers a taste of the best of Palawan.</p>`
    },
    {
        title: 'Sabang',
        code: 'SAB',
        url: '/destination/sabang',
        content: `<p>Nestled within the pristine island of Palawan lies Puerto Princesa, a city of natural wonders and enchanting beauty. Often referred to as the "City in a Forest", it boasts a harmonious blend of urban advancement and untouched nature. From the mesmerizing subterranean river in the Puerto Princesa Subterranean River National Park to its vibrant marine life in Honda Bay, the city provides a delightful mix of adventures waiting to be explored.</p>
        <br/>
        <p>Stepping into Puerto Princesa, you're greeted by stretches of powdery-white beaches, crystal-clear waters, and a horizon painted with lush mountains and age-old limestone formations. It's no wonder that this city has become a popular gateway to the wider archipelagic wonders of Palawan. Whether you're looking for serene beaches, thriving underwater ecosystems, or a touch of local culture, Puerto Princesa stands as a testament to the Philippines' claim as a tropical paradise.
        </p>
        <br/>
        <p>Beyond the natural attractions, Puerto Princesa exudes a rich history and culture. The hospitable locals, delectable seafood dishes, and handcrafted souvenirs encapsulate the spirit of Filipino warmth and creativity. As a destination that seamlessly combines nature, adventure, and cultural immersion, Puerto Princesa truly offers a taste of the best of Palawan.</p>`
    },
    {
        title: 'Astoria Palawan',
        code: 'AST',

    },
];

export { Destinations }